import React from "react"
import { Text } from "theme-ui"
import Layout from "../../components/layout"
import RoleTemplate from "../../components/roles/RoleTemplate"
import Pagination from "../../components/Pagination"

import portraitImage from "../../images/role-portraits/ranger-cutout.png"
import ranger01Image from "../../images/ability-cards/ranger-04.png"
import ranger02Image from "../../images/ability-cards/ranger-03.png"
import ranger03Image from "../../images/ability-cards/ranger-02.png"
import ranger04Image from "../../images/ability-cards/ranger-01.png"

import YAMLData from "../../data/learning-paths/ranger.yaml"

const RangerPage = () => (
  <Layout>
    <RoleTemplate
      name="ranger"
      src={portraitImage}
      bgGradient="to bottom, #AEDEE0, #AEDEE0"
      introDescription={
        <>
          <Text>The Ranger closes their eyes and places their hand on a tree trunk, learning the secrets of the forest. They stop to chat with a local squirrel, making a new friend. They speak myth, earning the favor of strangers.</Text>
          <Text>The Ranger is a great choice for people who want to play a skilled hunter and survivalist who thrives on the fringes of civilization.</Text>
        </>
      }
      imgWidth={740}
      imgLeftPos="auto"
      imgRightPos={[2, 4, 6]}
      abilityDescription="The Ranger is a well-known character in fantasy, but in Quest you’ll find it has more than just skill with speed, sense, and sword. Between their ability to enter the body of their animal partner and rouse common folk with story and song, Quest’s Ranger role is a powerful take on the classic form."
      abilityImg01={ranger01Image}
      abilityImg02={ranger02Image}
      abilityImg03={ranger03Image}
      abilityImg04={ranger04Image}
      yaml={YAMLData}
    />

    <Pagination parent="roles" prev="invoker" next="naturalist" />
  </Layout>
)

export default RangerPage
